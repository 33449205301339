import mainjson from '../config/main';
export default function GetData() {
        return {
        "apitype":"djs",
        "id":"dj_id",
        "subidname":"dj_name",
        "options":["edit","insert","delete"],
        "nameSingle":"dj",
        "nameMultiple":"djs",
        "filters":
            [
               
            ]
        ,
        "fields":{
            "field1":{
                "name":"Name",
                "field":"dj_name",
                "type":"TextInput",
                "required":true,
                "list":true,
            },
            "field2":{
                "name":"Start",
                "field":"dj_from",
                "type":"DateTimeInput",
                "required":true,
                "list":true
            },
            "field3":{
                "name":"End",
                "field":"dj_to",
                "type":"DateTimeInput",
                "required":true,
                "list":true
            },        
        }
    }
}